export const ListIDPage = 
{
  ManualKYC: 'ManualKYC',
  ManualBatchProcess: 'ManualBatchProcess',
  NewCustomer: 'NewCustomer',
  NewNote: 'NewNote',
  ManualCE: 'ManualCE'
}
export const PagesKYCUrl = {
	duoAuthentication: `${process.env.REACT_APP_Sub_Domain}/DuoAuth`
}

export const ListPageAccess = [
  {
    idPage: ListIDPage.ManualKYC,
    userTypeAccess: [1,3,5]
  },
  {
    idPage: ListIDPage.ManualBatchProcess,
    userTypeAccess: [1,3,5]
  },
  {
    idPage: ListIDPage.NewCustomer,
    userTypeAccess: [3,5]
  },
  {
    idPage: ListIDPage.NewNote,
    userTypeAccess: [3,5]
  },
  {
    idPage: ListIDPage.ManualCE,
    userTypeAccess: [1,3,5]
  }
]

export const GetIsPageAuthorize = (idPage: string, userType: number): boolean => {
  let returnValue = true;
  if (idPage !== undefined) {
    const itemPageAccess = ListPageAccess.find(w => idPage);
    returnValue = itemPageAccess!.userTypeAccess.includes(userType);
  }
  return returnValue;
}